import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from 'gatsby-plugin-intl';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="container" style={{minHeight:'50vh', display:'flex', flexDirection:'column', justifyContent:'center'}}>
      <div>
        <h1>404</h1>
        <p>Página no existente.</p>
        <Link className="btn-primary" to="/">Volver</Link>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
